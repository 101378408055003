import React, { useState } from 'react'
import Questions from './questions'
import TakeInfo from './take-info'
import TakePassword from './take-password'
import Results from './results'

function QuizQuestions () {
  const [answers, setAnswers] = useState([])
  const [current, setCurrent] = useState(0)

  const items = [
      <Questions key={'questions'} ifCompleted={(answers) => {
        setAnswers(answers)
        setCurrent(1)
      }} />,
      <TakeInfo answers={answers} key={'info'} ifCompleted={() => {
        setCurrent(2)
      }}/>,
      <TakePassword answers={answers} key={'password'} ifCompleted={() => {
        setCurrent(3)
      }}/>,
      <Results key={'results'}/>
  ]

  return (
    <div className='bg-white-base rounded-lg p-5 mb-16 h-screen'>
      { items[current] }
    </div>
  )
}

export default QuizQuestions
