import React, { useEffect, useState } from 'react'
import ProgressBar from './progress-bar'
import RatingQuestion from './rating-question'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { handleGetSubmitFeedback } from '../../../actions/user/feedbacks/Action'
import { Button, Spin } from 'antd'
import { useLocation } from 'react-router-dom'
import FeedbackContinue from './feedback-continue'
import { TlaError, TlaSuccess } from '../../../utils/messages'

function FeedbackQuestion ({ questions, showQuote, submitFeedback, user, self, isNext, receiver, publicSubmit }) {
  const { state } = useLocation()
  const [submitted, setSubmitted] = useState(false)
  const [range, setRange] = useState([0, 10])
  const [loading, setLoading] = useState(false)
  const [questionIndex, setQuestionIndex] = useState(0)
  const questionKeys = Object.keys(questions).slice(range[0], range[1])
  const currentQuestion = questions[questionKeys[questionIndex]]
  // ?.questions
  const [answers, setAnswers] = useState([])
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({ type: 'SET_CURRENT_QUESTION', payload: currentQuestion })
  }, [questionIndex, dispatch])

  const submitQuestions = () => {
    if (publicSubmit) {
      publicSubmit(answers)
    } else {
      setLoading(true)
      let userData = {}
      if (receiver) {
        userData = {
          giver: {
            id: user.id,
            email: user.email
          },
          receiver: {
            id: receiver.userId,
            email: receiver.resumeEmail
          }
        }
      } else {
        userData = {
          giver: {
            id: user.id,
            email: user.email
          },
          receiver: {
            id: state?.mainUser?.id ?? user.id,
            email: state?.mainUser?.email ?? user.email
          }
        }
      }
      const data = {
        ...userData,
        questions: answers
      }
      submitFeedback(data).then(() => {
        setLoading(false)
        setSubmitted(true)
        TlaSuccess('Feedback Submitted')
      }).catch((error) => {
        TlaError(error.response.data.message)
        setLoading(false)
        setSubmitted(false)
      })
    }
  }

  const goBack = () => {
    (questionIndex > 0) ? setQuestionIndex(questionIndex - 1) : showQuote(false)
  }

  const onFinish = () => {
    (questionIndex + 1) === questionKeys.length ? submitQuestions() : setQuestionIndex(questionIndex + 1)
  }

  const startNext10 = () => {
    isNext()
    setRange([0, 10])
    setQuestionIndex(0)
    setAnswers([])
    setSubmitted(false)
  }

  return (
        <Spin spinning={loading}>
            <div className={'w-full md:w-[728px] mx-auto mt-[30px] mb-4 p-2.5 md:p-0'}>
                {
                    submitted
                      ? <FeedbackContinue isSelfAssessment={receiver === undefined} doContinue={startNext10}/>
                      : <>
                            <div className={'px-7 md:px-0'}>
                              <ProgressBar totalValue={100} partialValue={Math.ceil(questionIndex * (100 / questionKeys.length))}
                                           questionNumber={questionIndex + 1}
                                           totalQuestions={questionKeys.length}/>
                            </div>
                            <div className="mt-[49px] md:mt-16 mb-[80px] px-2">
                                <RatingQuestion self={self}
                                    receiverInfo={receiver}
                                    zero={false}
                                    value={answers[questionIndex]?.newQuestionScore}
                                    setValue={(value) => {
                                      const newAnswers = answers.filter(obj => obj.skillId !== value.skillId).concat(value)
                                      setAnswers(newAnswers)
                                      if (questionKeys.length !== questionIndex + 1) {
                                        onFinish()
                                      }
                                    }}
                                    highScoreHelp={currentQuestion?.highestScoreHelp}
                                    lowScoreHelp={currentQuestion?.lowestScoreHelp}
                                    question={currentQuestion?.questions ?? currentQuestion}/>
                            </div>
                            <div className={'mx-auto w-fit flex gap-3 md:gap-10 pb-5'}>
                                <Button size={'large'} onClick={goBack} className={'btn w-full md:w-[154px]'}>
                                    Back
                                </Button>
                                <Button disabled={!answers[questionIndex]}
                                        size={'large'}
                                        onClick={onFinish}
                                        className={'btn btn-primary-600 w-full md:w-[185px]'}>
                                    {(questionIndex + 1) === 10 ? 'Submit' : 'Next'}
                                </Button>
                            </div>
                        </>
                }
            </div>
        </Spin>
  )
}

FeedbackQuestion.defaultProps = {
  self: false,
  publicSubmit: undefined
}
FeedbackQuestion.propTypes = {
  questions: PropTypes.array.isRequired,
  submitFeedback: PropTypes.func.isRequired,
  showQuote: PropTypes.func.isRequired,
  publicSubmit: PropTypes.func,
  isNext: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  receiver: PropTypes.object,
  self: PropTypes.bool
}

const mapStateToProps = (state) => ({
  questions: state.loginReducer.assessmentQuestions.questions,
  user: state.loginReducer.authUser
})

const mapDispatchToProps = (dispatch) => ({
  submitFeedback: (data) => dispatch(handleGetSubmitFeedback(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackQuestion)
