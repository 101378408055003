import React from 'react'
import QuizResult from '../../../components/public-components/nous-quiz/quiz-result'
import { useSelector } from 'react-redux'

function Results () {
  const { quizAnswer } = useSelector(state => state.commonReducer.publicQuizResults)
  return (
      <div className={'w-[90%] md:w-[800px] mx-auto'}>
        <QuizResult result={quizAnswer} isPublic />
      </div>
  )
}

export default Results
