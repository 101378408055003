import React from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Input, Select, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { contactTypes, getErrors } from '../../../utils'
import { handlePublicVerification } from '../../../actions/user/common/Action'
import { TlaError } from '../../../utils/messages'

function TakePassword ({ answers, ifCompleted }) {
  const [loading, setLoading] = React.useState(false)
  const { user } = useSelector(state => state.commonReducer.publicQuizResults)
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const onFinish = (values) => {
    setLoading(true)
    dispatch(handlePublicVerification(values)).then((res) => {
      setLoading(false)
      ifCompleted()
    }).catch((error) => {
      TlaError(error.response.data.message ?? getErrors(error.response.data?.errors))
      setLoading(false)
    })
  }

  return (
        <div className={ 'mx-auto ' }>
            <div className={ 'mb-5' }>
                <h3 className={ 'text-center text-gray-900 font-semibold text-3xl md:text-6xl leading-[38px] mb-6 mt-5' }>
                    Verify your email
                </h3>
                <p className={ 'w-[90%] md:w-fit text-center text-gray-500 text-xl mx-auto' }>
                    Check your email for a verification code
                </p>
                <p className={ 'text-sm text-center text-blue-600' }>{ user?.email }</p>
            </div>
            <Form className={ 'w-[90%] md:w-[594px] mx-auto' } initialValues={ {
              userId: user?.id,
              email: user?.email
            } } requiredMark={ false }
                  form={ form }
                  layout="vertical"
                  name="take-info-form" onFinish={ onFinish }>
                <Spin spinning={ loading } tip={ 'Please wait...' }>
                <Form.Item hidden name="userId" label="userId"
                               rules={ [
                                 {
                                   required: true,
                                   message: 'userId'
                                 }
                               ] }>
                        <Input size={ 'large' } placeholder={ 'Enter Verification code' }/>
                    </Form.Item>
                    <Form.Item hidden name="email" label="email"
                               rules={ [
                                 {
                                   required: true,
                                   message: 'email'
                                 }
                               ] }>
                        <Input size={ 'large' } placeholder={ 'Enter Verification code' }/>
                    </Form.Item>
                    <div>
                        <div className={ 'grid grid-cols-1 md:grid-cols-2 gap-2 mb-2' }>
                            <Form.Item name="verificationCode" label="Verification Code"
                                       rules={ [
                                         {
                                           required: true,
                                           message: 'Verification Code is Required'
                                         }
                                       ] }>
                                <Input size={ 'large' } placeholder={ 'Enter Verification code' }/>
                            </Form.Item>
                            <Form.Item
                                name={ 'currentStatus' }
                                rules={ [
                                  {
                                    required: true,
                                    message: 'Current Status is Required'
                                  }
                                ] }
                                label={ 'Current Status *' }>
                                <Select size={'large'}
                                    placeholder="Select Current Status"
                                    options={ contactTypes }
                                />
                            </Form.Item>
                        </div>
                        <div className={ 'grid grid-cols-1 md:grid-cols-2 gap-2 mb-2' }>
                            <Form.Item name="password"
                                       label={ 'Password*' }
                                       rules={ [
                                         { required: true, message: 'Password is required!' },
                                         { min: 8, message: '' }
                                       ] }>
                                <Input.Password
                                    size={ 'large' }
                                    type={ 'password' }
                                    placeholder="••••••••"/>
                            </Form.Item>
                            <Form.Item
                                dependencies={ ['password'] }
                                name="passwordConfirm"
                                label={ 'Confirm password*' }
                                rules={ [
                                  {
                                    required: true,
                                    message: 'Password is required!'
                                  }, ({ getFieldValue }) => ({
                                    validator (_, value) {
                                      if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve()
                                      }
                                      return Promise.reject(new Error('Password mismatch'))
                                    }
                                  }),
                                  {
                                    min: 8,
                                    message: 'Password should be at least 8 characters'
                                  }
                                ] }>
                                <Input.Password
                                    size={ 'large' }
                                    type="password"
                                    placeholder="••••••••"
                                />
                            </Form.Item>
                        </div>
                        <div className={'w-[185px] mx-auto'}>
                            <Form.Item>
                                <Button
                                    size={ 'large' }
                                    block
                                    className={ 'btn btn-primary-600' }
                                    htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Spin>
            </Form>
        </div>
  )
}

TakePassword.propTypes = {
  answers: PropTypes.any,
  ifCompleted: PropTypes.func
}

export default TakePassword
