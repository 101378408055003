import { Types } from '../../actions/user/common/Types'

const initialState = {
  userFeed: [],
  courses: {
    data: [],
    meta: {
      total: 0,
      pages: 0,
      page: 0
    }
  },
  course: {},
  lesson: {},
  lessons: [],
  totalFeed: 0,
  page: 1,
  skills: [],
  progress: [],
  personalities: [],
  userContentPosts: [],
  quizzes: {
    data: [],
    meta: {
      total: 0,
      pages: 1,
      page: 1
    }
  },
  singleQuiz: {},
  quizResults: [],
  publicQuizResults: {},
  publicAssessmentResults: {},
  quizQuestions: [],
  totalContentPosts: 0,
  contentPostPage: 1,
  totalContentPostPages: 1,
  invitationCode: null,
  emotions: {}
}

export default function commonReducer (state = initialState, action) {
  switch (action.type) {
    case Types.GET_USER_FEED:
      return {
        ...state,
        userFeed: action.reset ? action.payload.data.userFeed : state.userFeed.concat(action.payload.data.userFeed),
        submitFeedbackreset: action.reset,
        totalFeed: action.payload.data.count,
        page: Number(action.payload.data.page) + 1
      }
    case Types.GET_USER_CONTENT_POSTS: {
      return {
        ...state,
        userContentPosts: action.refresh ? action.payload.data.posts : state.userContentPosts.concat(action.payload.data.posts),
        totalContentPosts: action.payload.data.count,
        totalContentPostPages: action.payload.data.pages,
        contentPostPage: action.payload.data.page + 1
      }
    }
    case Types.GET_USER_QUIZZES: {
      return {
        ...state,
        quizzes: {
          data: action.refresh ? action.payload.data.quizForms : state.userQuizzes.concat(action.payload.data.quizForms),
          meta: {
            total: action.payload.data.count,
            pages: action.payload.data.pages,
            page: action.payload.data.page + 1
          }
        }
      }
    }
    case Types.GET_PUBLIC_QUIZZES: {
      console.log(action.payload.quizForms)
      return {
        ...state,
        quizzes: {
          data: action.payload.quizForms,
          meta: {
            total: action.payload.count,
            pages: action.payload.pages,
            page: action.payload.page + 1
          }
        }
      }
    }
    case Types.SET_SINGLE_QUIZ: {
      let singleQuiz = null
      state.quizzes.data.forEach(quiz => {
        if (quiz.id === action.payload) {
          singleQuiz = quiz
        }
      })
      return {
        ...state,
        singleQuiz
      }
    }
    case Types.GET_QUIZ_QUESTIONS: {
      return {
        ...state,
        quizQuestions: action.payload.data.linkQuestionQuizForm
      }
    }
    case Types.QUIZ_RESULTS: {
      return {
        ...state,
        quizResults: action.payload.data
      }
    }
    case Types.QUIZ_SUBMISSION: {
      return {
        ...state,
        publicQuizResults: action.payload.data
      }
    }
    case Types.SUBMIT_PUBLIC_ASSESSMENT: {
      return {
        ...state,
        publicAssessmentResults: action.payload
      }
    }
    case Types.GET_USER_COURSES: {
      return {
        ...state,
        courses: {
          data: action.refresh ? action.payload.data.courses : state.courses.data.concat(action.payload.data.courses),
          meta: {
            total: action.payload.data.count,
            pages: action.payload.data.pages,
            page: action.payload.data.page + 1
          }
        }
      }
    }
    case Types.GET_USER_COURSE_DETAILS: {
      return {
        ...state,
        course: action.payload.data.course
      }
    }
    case Types.GET_USER_LESSON: {
      return {
        ...state,
        lesson: action.payload.data.lesson
      }
    }
    case Types.GET_USER_MODULE_PROGRESS: {
      return {
        ...state,
        lessons: action.payload.data.lessons
      }
    }
    case Types.GET_SKILLS:
      return {
        ...state,
        skills: action.payload.data.skills,
        personalities: action.payload.data.personalities,
        // allSkillNoPagination: []
        allSkillNoPagination: [...action.payload.data.skills.aces, ...action.payload.data.skills.surges, ...action.payload.data.skills.sparks]
      }
    case Types.GET_PROGRESS_OVERTIME:
      return {
        ...state,
        progress: action.payload.data.skills
      }
    case Types.REMOVE_FEED:
      return {
        ...state,
        userFeed: state.userFeed.filter((feed) => feed.id !== action.id)
      }
    case Types.SET_INVITATION_CODE:
      return {
        ...state,
        invitationCode: action.payload
      }
    case Types.TOGGLE_SKILL:{
      const updatedSkill = action.payload.data.userSkill
      return {
        ...state,
        allSkillNoPagination: state.allSkillNoPagination.map(skill => skill.id === updatedSkill.id ? updatedSkill : skill)
      }
    }
    case Types.GET_EMOTIONS:{
      return {
        ...state,
        emotions: action.payload
      }
    }
    case Types.CHANGE_GPT_EXPERT:{
      return {
        ...state,
        gptExpert: action.payload
      }
    }
    default:
      return state
  }
}
