import api from '../../../utils/api'
import {
  courseEnrollment,
  getEmotions,
  getProgressOvertime,
  getQuizQuestions,
  getSkills,
  getUserContentPosts,
  getUserCourseDetails,
  getUserCourses,
  getUserFeed,
  getUserModuleLesson,
  getUserModuleProgress,
  getUserQuizResults,
  getUserQuizzes,
  questionSubmission,
  quizSubmission,
  sendInvitation,
  submitPublicSelfAssessment,
  toggleSkill,
  updateFeed,
  updateUserLessonProgress,
  validateOrg
} from './ActionCreator'
import { getUserAssessmentQuestions } from '../../authenticate/ActionCreators'

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetUserFeed = (page, userId, reset = null) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`userfeed/${userId}?page=${reset ? 1 : page}&pageSize=10`)
      .then((res) => {
        dispatch(getUserFeed(res.data, reset))
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}

export const handleGetUserContentPosts = (page, refresh = null) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/learn?page=${refresh ? 1 : page}&pageSize=15`)
      .then((res) => {
        dispatch(getUserContentPosts(res.data, refresh))
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}

export const handleGetUserCourses = (page, refresh = null, type = null) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/users/courses?page=${refresh ? 1 : page}&pageSize=15${type ? '&progress=' + type : ''}`)
      .then((res) => {
        dispatch(getUserCourses(res.data, refresh))
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}

export const handleGetUserCourseDetails = (courseId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/users/courses/${courseId}`)
      .then((res) => {
        dispatch(getUserCourseDetails(res.data))
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}

export const handleCourseEnrollment = (courseId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('/users/enrollments', { courseId })
      .then((res) => {
        dispatch(courseEnrollment(res.data))
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}

export const handleGetUserModuleLesson = (courseId, moduleId, contentId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/users/courses/${courseId}/module/${moduleId}/lessons/${contentId}`)
      .then((res) => {
        dispatch(getUserModuleLesson(res.data))
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}

export const handleGetUserModuleProgress = (moduleId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/users/courses/module/${moduleId}/lessons`)
      .then((res) => {
        dispatch(getUserModuleProgress(res.data))
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}

export const handleUpdateUserLessonProgress = (data, contentId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().patch(`/users/courses/progress/${contentId}`, data)
      .then((res) => {
        dispatch(updateUserLessonProgress(res.data))
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}

export const handleQuestionSubmission = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('/users/questions/complete', data)
      .then((res) => {
        dispatch(questionSubmission(res.data))
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}

export const handleGetUserQuizzes = (page, refresh = null) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/quiz-forms/user?page=${refresh ? 1 : page}&pageSize=15`)
      .then((res) => {
        dispatch(getUserQuizzes(res.data, refresh))
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}

export const handleGetUserQuizResults = (userId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/quiz-forms/${userId}/answers`)
      .then((res) => {
        dispatch(getUserQuizResults(res.data))
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}

export const handleGetQuizQuestions = (quizId, isPublic = false) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(isPublic ? `/public/quizzes/${quizId}/questions` : `/quiz-forms/${quizId}/questions`)
      .then((res) => {
        dispatch(getQuizQuestions(res.data))
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}

export const handleQuizSubmission = (data, isPublic = false) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post(isPublic ? '/public/quizzes/submit/' : '/quiz-forms/submit', data)
      .then((res) => {
        dispatch(quizSubmission(res.data))
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}

export const handlePublicVerification = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('/public/user/verify', data)
      .then((res) => {
        // dispatch(quizSubmission(res.data))
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}
export const handleContentPostClick = (postId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post(`/learn/record-click/${postId}`)
      .then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}

export const handleUpdateUserSkill = (skillId, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().put(`/question/skill-score/${skillId}`, data).then((res) => {
      dispatch(toggleSkill(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}
export const handleUpdateUserFeed = (feedId, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().patch(`/userFeed/consumed/${feedId}`, data).then((res) => {
      dispatch(updateFeed(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetUserSkills = (skillType = 'contact') => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/dashboard/skillscores/${skillType}`).then((res) => {
      dispatch(getSkills(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetEmotions = (type = 'self') => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/emotions/user?type=${type}`).then((res) => {
      dispatch(getEmotions(res.data.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetProgressOvertime = (data, skillType = 'contact') => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post(`/dashboard/skillscores/${skillType}`, data).then((res) => {
      dispatch(getProgressOvertime(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleSendInvitation = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('/contact/invite-contact', data).then((res) => {
      dispatch(sendInvitation(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const handleValidateOrg = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('/organization/validate', data).then((res) => {
      dispatch(validateOrg(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const handleGetPublicAssessmentQuestions = (userLevel) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get('/public/self-assessment-questions?currentStatus=' + userLevel)
      .then((res) => {
        dispatch(getUserAssessmentQuestions(res.data))
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}

export const handleSubmitPublicAssessment = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post('/public/self-assessment/submit', data)
      .then((res) => {
        dispatch(submitPublicSelfAssessment(res.data))
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}
