import React, { useState } from 'react'
import TakeUserLevel from './take-user-level'
import TakeSelfAssessment from './take-self-assessment'
import VerifyAccount from './verify-account'
import Results from './results'
import TakeUserEmail from './take-user-email'

function SelfAssessment () {
  const [current, setCurrent] = useState(0)
  const [answers, setAnswers] = useState({})

  const items = [
        <TakeUserLevel
            answers={ answers }
            key={ 'take-user-level' }
            ifCompleted={ (values) => {
              setAnswers(values)
              setCurrent(1)
            } }/>,
        <TakeSelfAssessment ifCompleted={ (values) => {
          const data = {
            questions: values,
            user: answers
          }
          setAnswers(data)
          setCurrent(2)
        } } key={ 'take-self-assessment' } answers={ answers }/>,
        <TakeUserEmail
            answers={ answers }
            key={ 'take-user-email' }
            ifCompleted={ (values) => {
              setAnswers(values)
              setCurrent(3)
            } }/>,
        <VerifyAccount
            ifCompleted={ () => setCurrent(4) }
            key={ 'verify-account' }/>,
        <Results key={ 'results' }/>
  ]
  return (
        <div className={ 'bg-white-base h-screen' }>
            { items[current] }
        </div>
  )
}

export default SelfAssessment
