import api from '../../../utils/api'
import { getPublicQuizzes, getUserCourseDetails, getUserCourses } from './ActionCreator'

export const handleGetPublicCourses = (page, refresh = null, type = null) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/public/courses?page=${refresh ? 1 : page}&pageSize=15${type ? '&progress=' + type : ''}`)
      .then((res) => {
        dispatch(getUserCourses(res.data, refresh))
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}

export const handleGetPublicCourseDetails = (courseId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/public/courses/${courseId}`)
      .then((res) => {
        dispatch(getUserCourseDetails(res.data))
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}

export const handleGetPublicQuizzes = (page) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/public/quizzes?page=${page}&pageSize=30`)
      .then((res) => {
        dispatch(getPublicQuizzes(res.data.data))
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}

export const handleGetPublicSingleQuiz = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/public/quizzes/${id}`)
      .then((res) => {
        dispatch(getPublicQuizzes({ quizForms: [res.data.data] }))
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}
