import React from 'react'
import QuizQuestions from './quiz-questions'
import { Link } from 'react-router-dom'
import { FiChevronLeft } from 'react-icons/fi'

function PublicQuizQuestion () {
  return (
        <div className={ 'bg-white-base py-5' }>
            <div className={ 'w-[90%] md:w-[861px] mx-auto' }>
                <Link to={ '/public/nous-quizzes' } className={ 'w-fit underline flex items-center gap-x-1' }>
                    <FiChevronLeft/> <span>Go Back</span>
                </Link>

            </div>
            <div className={ 'mt-3' }>
                <QuizQuestions/>
            </div>
        </div>
  )
}

export default PublicQuizQuestion
