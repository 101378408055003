import React, { useEffect, useState } from 'react'
import { Button, Spin } from 'antd'
import { Quotes } from '../../../utils/quotes'
import { connect, useSelector } from 'react-redux'
import { handleGetAssessmentQuestions, handleGetUserAssessmentQuestions } from '../../../actions/authenticate/Actions'
import PropTypes from 'prop-types'
import FeedbackQuestion from './feedback-question'
import { useLocation, useNavigate } from 'react-router-dom'
import FeedbackHeader from '../feedback-header'
import SelfAssessmentHeader from '../self-assessment-header'
import UserFeedbackQuestion from './user-feedback-question'

function GiveFeedback ({ getQuestions, getUserAssessmentQuestions, isSelfAssessment, isQuickAction, changePerson }) {
  const [loading, setLoading] = useState(true)
  // eslint-disable-next-line no-unused-vars
  const [quote, setQuote] = useState(Quotes[Math.floor(Math.random() * Quotes.length)])
  const [isQuestion, setIsQuestions] = useState(false)
  // const questions = useSelector((state) => state.loginReducer.assessmentQuestions.questions)
  const receiver = useSelector((state) => state.loginReducer.assessmentQuestions.receiver_info)
  const user = useSelector((state) => state.loginReducer.authUser)
  const currentQuestion = useSelector((state) => state.feedbacksReducer.currentQuestion)
  const { state } = useLocation()

  // getting contactId for Invite Section
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const contactId = queryParams.get('contactId')

  useEffect(() => {
    setQuote(Quotes[Math.floor(Math.random() * Quotes.length)])
    if (state || contactId) {
      setTimeout(() => setIsQuestions(true), 3000)
    }

    if (contactId) {
      getQuestions(null, null, contactId).then(() => {
        setLoading(false)
      })
      return
    }
    if (isSelfAssessment) {
      getUserAssessmentQuestions().then(() => {
        setLoading(false)
      })
    } else {
      const giverId = state?.contactUserId
      const receiverId = state?.mainUser?.id
      getQuestions(giverId, receiverId).then(() => {
        setLoading(false)
      }).catch((err) => {
        console.log(err)
        setLoading(false)
      })
    }
  }, [location.pathname])
  const fetchNextQuestions = (isSelfAssessed = false) => {
    setLoading(true)
    if (isSelfAssessed) {
      getQuestions(user.id, user.id).then(() => {
        setLoading(false)
      })
    } else if (contactId) {
      getQuestions(null, null, contactId).then(() => {
        setLoading(false)
      })
    } else {
      const giverId = state.contactUserId
      const receiverId = state.mainUser.id
      getQuestions(giverId, receiverId).then(() => {
        setLoading(false)
      })
    }
  }

  const selectComponent = () => {
    if (contactId) {
      return (<FeedbackQuestion self={ false } receiver={ receiver } isNext={ fetchNextQuestions }
                                      showQuote={ setIsQuestions }/>)
    } else if (state === null) {
      return (<UserFeedbackQuestion self={ state === null } isNext={ fetchNextQuestions }
                                          showQuote={ setIsQuestions }/>)
    } else {
      return (
                <FeedbackQuestion self={ isSelfAssessment } isNext={ fetchNextQuestions }
                                  showQuote={ setIsQuestions }/>)
    }
  }
  const navigate = useNavigate()
  return (
        <div className={ 'bg-white-base rounded-lg mx-auto max-w-[800px]' }>
            <Spin spinning={ loading }>
                <div>
                    {
                        ((state || contactId) && isSelfAssessment === false)
                          ? <>
                                <FeedbackHeader
                                    showReport={ isQuestion }
                                    questionId={ currentQuestion?.id }
                                    receiver={ receiver !== undefined }
                                />
                                {
                                    (isQuickAction && isSelfAssessment === false && isQuestion === false) &&
                                    <div>
                                        <div>
                                            <p className={ 'font-semibold text-sm md:text-xl mt-3.5 text-center mb-5' }>
                                                Change Person or Continue to give feedback {state?.mainUser?.userInformation?.name}
                                            </p>
                                            <div className={ 'flex items-center justify-center gap-4 md:gap-11' }>
                                                <Button size={ 'large' } onClick={ changePerson }
                                                        className={ 'btn w-full md:w-[154px]' }>
                                                    Change
                                                </Button>
                                                <Button size={ 'large' } onClick={ () => setIsQuestions(true) }
                                                        className={ 'btn btn-primary-600 w-full md:w-[185px]' }>
                                                    Continue
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                          : <>
                                {
                                    isQuestion
                                      ? <SelfAssessmentHeader showReport={ isQuestion } questionId={ currentQuestion?.id }/>
                                      : <div className={ `${isQuickAction ? '' : 'py-[30px] px-6 mt-10 md:mt-20'}` }>
                                            <div className={ 'rounded-lg p-4' }>
                                                <p className={ 'text-success-500 text-xl font-semibold' }>
                                                    Self Assessment
                                                </p>
                                                <p className={ 'font-semibold text-2xl mt-3.5' }>
                                                    You need to take self-assessment to set up your profile on the platform.
                                                </p>
                                                <p className={ 'text-gray-500 text-base mb-[40px]' }>
                                                    After the self-assessment, you will be able to unlock all feature on
                                                    Noustro.
                                                </p>
                                                <div className={ 'flex items-center justify-center gap-4 md:gap-11' }>
                                                    <Button size={ 'large' } onClick={ () => navigate(-1) }
                                                            className={ 'btn w-full md:w-[154px]' }>
                                                        Cancel
                                                    </Button>
                                                    <Button size={ 'large' } onClick={ () => setIsQuestions(true) }
                                                            className={ 'btn btn-primary-600 w-full md:w-[185px]' }>
                                                        Start Now
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </>
                    }
                    {
                        isQuestion ? selectComponent() : <></>
                    }
                </div>
            </Spin>
        </div>
  )
}

GiveFeedback.defaultProps = {
  isQuickAction: false
}

GiveFeedback.propTypes = {
  isSelfAssessment: PropTypes.bool.isRequired,
  getQuestions: PropTypes.func.isRequired,
  changePerson: PropTypes.func,
  getUserAssessmentQuestions: PropTypes.func.isRequired,
  isQuickAction: PropTypes.bool
}

const mapDispatchToProps = (dispatch) => ({
  getQuestions: (giverId, receiverId, contactId) => dispatch(handleGetAssessmentQuestions(giverId, receiverId, contactId)),
  getUserAssessmentQuestions: () => dispatch(handleGetUserAssessmentQuestions())
})

export default connect(null, mapDispatchToProps)(GiveFeedback)
