import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ProgressBar from '../../../pages/feedback/give-feedback/progress-bar'
import { handleGetQuizQuestions, handleQuizSubmission } from '../../../actions/user/common/Action'
import { Button, Spin } from 'antd'
import { connect, useSelector } from 'react-redux'
import OptionItemCourses from '../../../pages/courses/questions/option-item-courses'
import QuizResult from './quiz-result'

function QuizQuestions ({ singleQuiz, questions, getQuizQuestions, submitQuiz, isPublic }) {
  const [loading, setLoading] = useState(true)
  const [submitted, setSubmitted] = useState(false)
  const [answers, setAnswers] = useState([])
  const [questionIndex, setQuestionIndex] = useState(0)
  const [selected, setSelected] = useState(-1)
  const [result, setResult] = useState({})
  const userId = useSelector(state => state.loginReducer?.authUser?.id)
  const formId = questions[questionIndex]?.quizFormId
  const question = questions[questionIndex]?.quizQuestion ?? {}
  const options = questions[questionIndex]?.quizQuestion.options ?? []

  const nextQuestion = () => {
    if (questions.length === questionIndex + 1) {
      setLoading(true)
      submitQuiz({ formId, userId, rawScores: answers }).then((res) => {
        setLoading(false)
        setSubmitted(true)
        setResult(res.data.data.quizAnswer)
      })
    } else {
      setQuestionIndex(questionIndex + 1)
    }
  }
  const checkAns = (index) => {
    const isChecked = selected === index
    if (isChecked) return 'border-blue-500 bg-blue-500 text-blue-500'
    else return 'border-gray-300 text-black-900'
  }
  useEffect(() => {
    setSelected(answers[questionIndex]?.index ?? -1)
  }, [questionIndex])
  useEffect(() => {
    getQuizQuestions(singleQuiz.id, isPublic).then((res) => setLoading(false))
  }, [])
  return (
    <div className='bg-white-base rounded-lg mx-auto w-full md:w-[861px] mb-16'>
      <Spin spinning={loading}>
        {
          !submitted
            ? <div>
                <div className="quiz-header py-4 px-6 flex flex-col gap-2 border-b">
                  <h2>NousQuiz 1</h2>
                  <h1 className='text-lg font-medium text-gray-900'>{singleQuiz.titleQuizForm}</h1>
                </div>
                <div className="quiz-body p-5">
                  <div className={'px-7 md:px-0'}>
                    <ProgressBar totalValue={100} partialValue={Math.floor((questionIndex / questions.length) * 100)}
                      questionNumber={questionIndex + 1}
                      totalQuestions={questions?.length} />
                  </div>
                  <div className={'w-full rounded-10 bg-white-base p-5 md:p-[30px]'}>
                    <p className={'text-black-900 font-medium text-lg mb-4'}>
                      {question.title}
                    </p>
                    <div className={`flex flex-col items-start justify-start gap-4 ${question.completed ? 'pointer-events-none' : ''}`}>
                      {
                        options.map(({ score, title }, index) => {
                          return (
                            <OptionItemCourses
                              onClick={() => {
                                setSelected(index)
                                const newAnswers = answers.filter(obj => obj.questionId !== question.id).concat({ questionId: question.id, score, index })
                                setAnswers(newAnswers)
                                if (questions.length > questionIndex + 1) {
                                  nextQuestion()
                                }
                              }}
                              key={index} text={title}
                              colors={checkAns(index)}
                              selected={selected === index}
                            />
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
                <div className="flex justify-center gap-5 p-5">
                  <Button size={'large'} onClick={() => setQuestionIndex(questionIndex - 1)} disabled={questionIndex === 0} className={'btn w-[76px] md:w-[154px]'}>
                    Back
                  </Button>
                  <Button
                    size={'large'}
                    onClick={nextQuestion}
                    disabled={!answers[questionIndex]}
                    className={`btn w-[76px] md:w-[154px] ${!answers[questionIndex] ? 'btn-primary opacity-80' : 'bg-[#1570EF] text-white-base'}`}>
                    { questionIndex + 1 === questions.length ? 'Submit' : 'Next'}
                  </Button>
                </div>
            </div>
            : <QuizResult result={result} />
        }
      </Spin>
    </div>
  )
}

QuizQuestions.defaultProps = {
  isPublic: false
}

QuizQuestions.propTypes = {
  quiz: PropTypes.object,
  isPublic: PropTypes.bool,
  singleQuiz: PropTypes.object,
  questions: PropTypes.array,
  submitQuiz: PropTypes.func,
  getQuizQuestions: PropTypes.func
}

const mapStateToProps = (state) => ({
  singleQuiz: state.commonReducer.singleQuiz,
  questions: state.commonReducer.quizQuestions
})

const mapDispatchToProps = (dispatch) => ({
  getQuizQuestions: (id, isPublic) => dispatch(handleGetQuizQuestions(id, isPublic)),
  submitQuiz: (data) => dispatch(handleQuizSubmission(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(QuizQuestions)
