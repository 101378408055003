import React, { useEffect, useState } from 'react'
import { Spin } from 'antd'
import { Quotes } from '../../../utils/quotes'
import { connect, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import FeedbackQuestion from '../../feedback/give-feedback/feedback-question'
import SelfAssessmentHeader from '../../feedback/self-assessment-header'
import { handleGetPublicAssessmentQuestions } from '../../../actions/user/common/Action'

function TakeSelfAssessment ({ getUserAssessmentQuestions, ifCompleted, answers }) {
  const [loading, setLoading] = useState(true)
  // eslint-disable-next-line no-unused-vars
  const [quote, setQuote] = useState(Quotes[Math.floor(Math.random() * Quotes.length)])
  const [isQuestion, setIsQuestions] = useState(false)
  const currentQuestion = useSelector((state) => state.feedbacksReducer.currentQuestion)
  const { state } = useLocation()

  // getting contactId for Invite Section
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const contactId = queryParams.get('contactId')

  useEffect(() => {
    setQuote(Quotes[Math.floor(Math.random() * Quotes.length)])
    if (state || contactId) {
      setTimeout(() => setIsQuestions(true), 3000)
    }

    getUserAssessmentQuestions(answers.currentStatus).then(() => {
      setLoading(false)
      setIsQuestions(true)
    })
  }, [location.pathname])
  const fetchNextQuestions = (isSelfAssessed = false) => {
    setLoading(true)
    /* getQuestions(user.id, user.id).then(() => {
          setLoading(false)
      }) */
  }

  const selectComponent = () => {
    return <FeedbackQuestion publicSubmit={(values) => {
      ifCompleted(values)
    }} self={ true } isNext={ fetchNextQuestions } showQuote={ setIsQuestions }/>
  }

  return (
        <div className={ 'bg-white-base rounded-lg mx-auto max-w-[800px]' }>
            <Spin spinning={ loading }>
                <div>
                    {
                        isQuestion && <>
                          <SelfAssessmentHeader showReport={ isQuestion } questionId={ currentQuestion?.id }/>
                          {selectComponent()}
                        </>
                    }
                </div>
            </Spin>
        </div>
  )
}

TakeSelfAssessment.propTypes = {
  getUserAssessmentQuestions: PropTypes.func.isRequired,
  ifCompleted: PropTypes.func,
  answers: PropTypes.any
}

const mapDispatchToProps = (dispatch) => ({
  getUserAssessmentQuestions: (userLevel) => dispatch(handleGetPublicAssessmentQuestions(userLevel))
})

export default connect(null, mapDispatchToProps)(TakeSelfAssessment)
