import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { handleGetPublicQuizzes, handleGetPublicSingleQuiz } from '../../../actions/user/common/PublicActions'
import SingleNousQuiz from '../../../components/public-components/nous-quiz/single-nous-quiz'
import { Spin } from 'antd'
import { useParams } from 'react-router'

function PublicQuizzes ({ getQuizzes, getSingleQuiz, quizzes, isSingle }) {
  const [loading, setLoading] = useState(true)

  const { quizId } = useParams()
  useEffect(() => {
    if (isSingle) {
      getSingleQuiz(quizId).then(() => setLoading(false)).catch(() => setLoading(false))
    } else {
      getQuizzes(1).then(() => setLoading(false)).catch(() => setLoading(false))
    }
  }, [])
  const { data } = quizzes
  return (
        <div className={'bg-white-base py-5'}>
            <Spin spinning={ loading }>
                <div className={ 'mb-8' }>
                    <h3 className={ 'text-center text-gray-900 font-semibold text-3xl md:text-6xl leading-[38px] mb-6 mt-[100px]' }>
                        See our Quizzes
                    </h3>
                    <p className={ 'w-[90%] md:w-[768px] text-center text-gray-500 text-xl mx-auto' }>
                        The world is complex. You must know what is the rationale behind your actions.
                        You must know where you stand, and where you want to be.
                    </p>
                </div>
                <div className={ 'grid grid-cols-1 gap-3 w-[90%] md:w-[829px] mx-auto' }>
                    {
                        data.map((quiz, i) => (
                            <div key={ i } className='border-[#F4F4F4] border rounded-lg'>
                                <SingleNousQuiz quiz={ quiz } isPublic/>
                            </div>
                        ))
                    }
                </div>
            </Spin>
        </div>
  )
}

PublicQuizzes.defaultProps = {
  isSingle: false
}

PublicQuizzes.propTypes = {
  quizzes: PropTypes.object.isRequired,
  isSingle: PropTypes.bool,
  getSingleQuiz: PropTypes.func.isRequired,
  getQuizzes: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  quizzes: state.commonReducer.quizzes
})

const mapDispatchToProps = (dispatch) => ({
  getQuizzes: (page) => dispatch(handleGetPublicQuizzes(page)),
  getSingleQuiz: (id) => dispatch(handleGetPublicSingleQuiz(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(PublicQuizzes)
